/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {HrefLangManager, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aid-success/why-you-should-wear-your-hearing-aids-at-home/'], ['en', 'https://www.hear.com/resources/hearing-aid-success/why-you-should-wear-your-hearing-aids-at-home/'], ['en-US', 'https://www.hear.com/resources/hearing-aid-success/why-you-should-wear-your-hearing-aids-at-home/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aid-success/why-you-should-wear-your-hearing-aids-at-home/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "heres-why-you-should-wear-your-hearing-aids-at-home",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#heres-why-you-should-wear-your-hearing-aids-at-home",
    "aria-label": "heres why you should wear your hearing aids at home permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Here’s Why You Should Wear Your Hearing Aids at Home"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Do you think you only need to wear your hearing aids when you go out? Or that they’re just a tool for better communication? You might be surprised that you do most of your hearing with your brain, not with your ears. Therefore, experts recommend that you wear your hearing aids every day – even when you’re at home alone. This consistently stimulates your brain with sounds for a better overall hearing experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For example, to train for a marathon, you’d run different distances to prepare for the big day of 26.2 miles. Most days you’d run 5 miles, other days 10 miles, and only sometimes you’d go over 15 miles. Your brain is no different. By wearing your hearing aids consistently, even at home alone, you’ll hear more sounds around the house. Then you’ll have an easier time adapting to hearing situations outside of the house that tend to be more challenging."), "\n", React.createElement(LandingPageCta, {
    copy: "Get better hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "keep-your-brain-active",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#keep-your-brain-active",
    "aria-label": "keep your brain active permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Keep Your Brain Active"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With hearing loss, your brain is deprived of sound, which is commonly referred to as auditory deprivation. You may not think it’s important to hear your footsteps around the house, but subtle sounds keep your brain stimulated. For example, researchers at John Hopkins found that people with mild hearing loss were twice as likely to develop dementia. And those with severe hearing loss were 5 times more likely to develop it when left untreated. Initially, you might be surprised by all the sounds, but over time your brain will automatically tune them out."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you only wear your hearing aids to go out, there’s a good chance of feeling overstimulated in those situations. Not only can this be uncomfortable, but it makes you less effective when trying to understand speech. By wearing your hearing aids consistently at home, you’re exposing your brain to more sounds more often. Then when you’re in a challenging listening situation, it’s easier for your brain to filter out the noise and focus on speech."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "stay-alert-and-aware",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#stay-alert-and-aware",
    "aria-label": "stay alert and aware permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Stay Alert and Aware"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For safety reasons, it’s much easier to hear alarms, sirens, or other warning signals with your hearing aids. You’ll also hear a leaky faucet, running toilet, or problems with your appliances that will signal the need for repair. This is also why you’d want to wear your hearing aids in the car. Not only will they help you locate the sound of oncoming sirens, but they’ll help you notice your squeaky brakes or that your turning signal is still on."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "stay-connected",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#stay-connected",
    "aria-label": "stay connected permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Stay Connected"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids can connect to your smartphone and TV — which makes it easier to watch shows and videos. If your hearing aids are ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth enabled"), ", you can stream the audio directly to your hearing aids. This way you’ll be able to clearly understand every word of the conversation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remember that better hearing is a journey and patience is required. But the more consistently you wear your hearing aids, the less you’ll actually notice they’re there."), "\n", React.createElement(LandingPageCta, {
    copy: "Find new and better hearing aids",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
